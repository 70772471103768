@import '../../../../../../../styles/index';

.profile__wrapper {
  background-color: $white-color;
  padding: 20px;

  .profile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .profile__title {
      color: $primary-color;
      font-weight: $font-weight-bolder;
    }

    .actions__wrapper {
      display: flex;

      &>*:first-child {
        margin-right: 10px;
      }
    }
  }

  .profile__table {
    display: grid;
    .edit-profile__form {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;

			label {
				font-size: 14px;
				font-weight: $font-weight-bolder;
			}
    }

    .form__disabled {
			div > textarea,
      div > input {
        background-color: transparent;
				padding: 0;
				color: $nero-color;
      }
    }

		.properties-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.profile__field {
				display: flex;
				flex-direction: column;
				margin-bottom: 10px;

				& > span {
					font-weight: $font-weight-bolder;
					font-size: 14px;
				}

				.label__active {
					color: $primary-dark-color;
				}

				.label__expired {
					color: $primary-color;
				}

				.label__removed {
					color: $rosso-color;
				}

				.count {
					font-size: $base-font-size;
					margin: 10px 0;
					height: 50px;
					display: flex;
					align-items: center;
				}
			}
		}
  }
}
