@import '../../../../../styles/index';

.details__wrapper {
	display: flex;
	flex-direction: column;

	& > * {
		margin-bottom: 20px;
	}

	.house__info {
		background-color: $white-color;
		padding: 20px;

		.info__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 25px;

			.profile__title {
				color: $primary-color;
				font-weight: $font-weight-bolder;
			}

			.actions__wrapper {
				display: flex;

				&>*:first-child {
					margin-right: 10px;
				}
			}
		}
	}
	
	.delete-btn__wrapper {
		width: 100%;
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
}
