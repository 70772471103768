@import '../../../styles/index.scss';

.field__wrapper {
  margin-bottom: 10px;

	.field__textarea,
  .field__input {
    width: 100%;
    font-size: 18px;
    border: none;
    padding: 0 10px;
    margin-bottom: 10px;
    color: $nero-color;

    &:focus-visible {
      outline: none;
    }

		&::placeholder {
			color: $nero-color;
			opacity: 0.5;
		}
  }

	.field__textarea {
		resize: vertical;
		min-height: 50px;
		padding-top: 15px;
	}

	.field__textarea:disabled {
		resize: none;
	}

  .field__label {
    display: block;
    margin-bottom: 10px;
  }

  .white {
    background: $white-color;
  }

  .bianco {
    background: $bianco-color;
  }

  .error {
    display: flex;
    color: $rosso-color;
    font-size: 12px;
    line-height: 1.2;
  }
}

.field__wrapper.field__disabled > input.field__input,
.field__wrapper.field__disabled > input.field__textarea {
  background: transparent;
  padding: 0;
  color: $nero-color;
}

.field__wrapper.field__disabled > input.field__textarea {
  padding: 15px 0;
}

.full-height {
	height: 100%;

	.field__textarea {
		height: calc(100% - 24px);
	}
}
