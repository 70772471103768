@import '../../../../styles/index.scss';

.container__inner {
  display: flex;
  border-radius: 20px;
  width: 100%;
  position: relative;

  .modal__text {
    font-size: 24px;
    line-height: 1.8;
    text-align: center;

    .modal__house-name {
      display: block;
    }
  }

  .content {
    width: 100%;
  }
}
.main__content{
  width: 95%;
  margin: 0 auto;
}