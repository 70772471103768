@import '../../../../../../../../../styles/index';

.modal__upload-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &>button {
    margin-top: 30px;
    margin-left: auto;
  }

  .upload-img__btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 15px;

    .btn__label {
      padding: 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        height: 30px;
        margin-bottom: 15px;
      }
    }

    .error {
			margin-top: 15px;
      color: #f34245;
    }
  }

  .preview__wrapper {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

		.uploaded-image__wrapper {
			position: relative;
			border-radius: 10px;
			height: 100px;
			padding: 5px;
			cursor: pointer;

			.uploaded-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
				border-radius: 10px;
				position: relative;

			}

			&:hover {
				border: 2px solid $rosso-color;
			}

			&:hover .uploaded-image {
				filter: blur(2px);
			}

			&:hover::after {
				content: '';
				background: url('../../../../../../../../../assets/svg/bin.svg') no-repeat;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 22px;
				height: 22px;
				transform: translate(-50%, -50%);
			}
		}
  }
}

.drag-container {
  height: 300px;
  width: 100%;
  border: dashed rgb(206, 206, 206) 2px;
  border-radius: 10px;
  display: flex;
}

.overlay {
  border-color: #f34245;
  background-color: gray;
}
