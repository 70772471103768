@import '../../../../../../../../../styles/index';

.error {
  display: flex;
  color: $rosso-color;
  font-size: 12px;
  line-height: 1.2;
	margin-top: 10px;
}

.modal__upload-image {
	padding-top: 20px;
	overflow: auto;
	
	& > form {
		width: 400px;
	}

	.select__wrap {
		margin-bottom: 20px;
	}

  .uploaded-image {
    height: auto;
		max-height: 30vh;
    width: 100%;
    object-fit: contain;
    display: flex;
    margin: 20px 0;
  }

	.form-actions {
		& > button {
			margin-top: 10px;
		}

		.upload-img__btn {
			background-color: $violet-color;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			cursor: pointer;
			width: 100%;
			border-radius: 10px;
			height: 50px;
			margin-bottom: 15px;
	
			span {
				color: $white-color;
				font-weight: $font-weight-bolder;
				margin-left: 10px;
			}
	
			.btn__label {
				cursor: pointer;
				display: flex;
				align-items: center;
	
				img {
					display: block;
					height: 100%;
				}
			}
		}
	}
}
