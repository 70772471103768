@import '../../../../styles/index.scss';

.container__inner {
  display: flex;
	align-items: center;
	justify-content: center;
  width: 100%;

  .login__container {
    color: #06262D;
    width: 375px;
    display: flex;
    flex-direction: column;

    h1 {
      color: $nero-color;
      margin-bottom: 15px;
    }

    .login__form {
      display: flex;
      flex-direction: column;
      width: 100%;

      &>*:last-child {
        margin-top: 40px;
      }
    }

    .forgot-pwd__link {
      color: $primary-color;
      text-decoration: underline;
      font-size: 16px;
      text-align: right;
      cursor: pointer;
    }

    .houseplus-acc__btn {
      padding: 38px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .btn__title {
        font-weight: $font-weight-bolder;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .btn__text {
        font-weight: $font-weight-normal;
        font-size: 14px;
      }
    }

    .errorMsg {
      display: flex;
      color: #FC440F;
      font-size: 12px;
      line-height: 1.2;
    }
  }
  .logo {
    margin: 0 auto 40px;
    display: block;
    width: 340px;
    height: 140px;
    object-fit: contain;
  }
}
