@import '../../../../../../../../../styles/index';

.actions__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  &>button:first-child {
    margin-right: 10px;
  }
}

.gallery__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  position: relative;

  .list-item__wrapper {
    border-radius: 10px;
    cursor: pointer;
    height: 150px;
    position: relative;

    .cover__image {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .active-image {
    border: 3px solid $primary-color;
  }

  .upload-img__btn {
    background-color: $violet-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    cursor: pointer;

    span {
      color: $white-color;
      font-weight: $font-weight-bolder;
      margin-left: 10px;
    }
  }

  .change-order-btn__wrapper {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}


.list-item__draggable {
  cursor: grabbing !important;
  position: relative;

  &:hover {
    border: 3px solid $primary-color;
  }

  .btn__remove {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

.list__img {
  width: 100%;
  height: 100%;
  max-width: 330px;
  padding: 5px;
  object-fit: cover;
  border-radius: 10px;
}
