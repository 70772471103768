@import '../../../../../../../styles/index.scss';

.confirm-modal__title {
  font-weight: $font-weight-bolder;
}

.confirm-modal__actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  & > button:first-child {
    margin-right: 15px;
  }
}



.uploadSection {
  height: 200px;
  margin-top: 20px;
}

.uploadSection-img {
  margin-top: 30px;
  width: 145px;
  height: 141px;

  /* Gradient */

  background: linear-gradient(232.71deg, #0EEDFF -23.85%, #AB74F9 109.37%);
  opacity: 0.1;
  border-radius: 20px
}


.upload-img__btn {
  background-color: $violet-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 15px;

  span {
    color: $white-color;
    font-weight: $font-weight-bolder;
  }

  .btn__label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      height: 100%;
    }
  }
}
