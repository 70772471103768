html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-y: auto;
	@include full-screen;
}

input {
  border-radius: 4px;
}

#root {
  background-position: right bottom, left top;
  background-repeat: no-repeat;
  justify-content: flex-start;
}

table,
tbody,
thead,
tfoot,
th,
tr,
td {
  font-weight: unset;
}

.required {
  color: $red-color;
  @include font(normal, normal, 14px, 140%);
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,577;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,577;1,600;1,700;1,800;1,900&display=swap");

::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  width: 8px;
  height: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d7d6d6;
  -webkit-box-shadow: 0 0 1px #f4f4f4;
}
