@import '../../../../../../../styles/index';

.facilityAndDisability__wrapper {
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px 20px 70px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .facilityAndDisability__inner {
    max-width: 45%;
    min-width: 30%;
    width: auto;
    margin:10px;
    .facilityAndDisability__inner__form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .actions {
        display: flex;
      }
      .error {
        display: flex;
        color: $rosso-color;
        font-size: 12px;
        line-height: 1.2;
      }
    }
  }
}
