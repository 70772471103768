.container__inner {
	& > * {
		margin-bottom: 20px;
		border-radius: 10px;
	}

	.delete-btn__wrapper {
		width: 100%;
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}
}
