@import "./modules/colors.scss";
@import "./modules/mixins.scss";
@import "./modules/variables.scss";
@import "./modules/reset.scss";

* {
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $base-font-size;
  letter-spacing: $base-letter-spacing;
  line-height: $base-line-height;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
	background-color: hsl(185, 46%, 95%);
	padding: 40px 0;
  // @include full-screen;
	height: 100%;
	min-height: 100vh;
  display: flex;
  flex-shrink: 1;
}

h1,
.h1 {
  font-weight: $font-weight-bolder;
  font-size: 42px;
  line-height: 1.8;
}

h2,
.h2 {
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 1.5;
}

h3,
.h3 {
  font-weight: $font-weight-bold;
  font-size: 18px;
}

h4,
.h4 {
  font-weight: $font-weight-bold;
  font-size: 16px;
}

h5,
.h5 {
  font-weight: $font-weight-bold;
  font-size: 14px;
}

b,
strong {
  font-weight: $font-weight-bolder;
}

button {
  outline: none;
  cursor: pointer;
}

span,
p,
b,
strong,
h1,
h2,
h3,
h4,
div {
  color: $nero-color;
}

.eye-icon {
	width: 22px;
	height: 18px;
}

.badge-completed {
  background: $primary-gradient;
}

.badge-pending {
  background: #babd17be;
}

.badge-deleted {
  background: $red-color;
}

.cover-image-tooltip {
  width: 24px;
  height: 24px;

  &>img {
    width: 100%;
    object-fit: contain;
  }

  &:hover::after {
    content: 'Cover image';
    position: absolute;
    font-size: 14px;
    top: -30px;
    right: 0;
    width: max-content;
    transform: translateX(35%);
    background-color: $primary-color;
    color: $white-color;
    padding: 5px;
    border-radius: 5px;
  }
}

// The main container element
.Collapsible {
  background-color: $white-color;
  margin-bottom: 20px;
}


//The content within the collaspable area
.Collapsible__contentInner {
  padding: 20px 10px;
  border: 1px solid $white-color;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid $gray-color;
  padding: 10px;
  background: $gray-color;
  color: $black-color;


  &:after {
    font-family: $fontAwesome-family;
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;


}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}
