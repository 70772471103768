//$white-color: #FFF7FA;
$white-color: #FAFDFF;
$gray-color: #ededed80;
$dark-gray-color: #51676C;
$light-gray-color: #ededed;
$green-color: #067C89;
$bianco-color: #ecf7f8;
$bianco-light-color: #EBFCFE;
$primary-color: #078EA6;
$primary-dark-color: #0f687c;
$primary-light-color: #08a6b6;
$primary-gradient: linear-gradient(83.15deg, #0A7E98 0%, #0CA1B1 100%);
$secondary-gradient: linear-gradient(87.04deg, #5E239D 0%, #8129BC 100%);
$violet-gradient: linear-gradient(87.04deg, rgba(94, 35, 157, 0.5) 0%, rgba(129, 41, 188, 0.5) 100%);
$violet-light-gradient: linear-gradient(87.04deg, rgba(94, 35, 157, 0.1) 0%, rgba(129, 41, 188, 0.1) 100%);
$black-color: #333333;
$nero-color: #034349;
$red-color: #fd7072;
$rosso-color: #FC440F;
$giallo-color: #CFD11A;
$blue-color: #151ee7;
$violet-color: #5E239D;
