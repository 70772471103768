.pagination {
	.arrow__back,
	.arrow__next {
		background-color: transparent;
		border: none;
		padding: 5px 10px;
	}

	.arrow__next {
		svg {
			transform: rotate(180deg);
		}
	}

	.text {
		display: inline-block;
		margin: 0 10px;
		font-weight: bold;
	}

	.btn__disabled {
		cursor: not-allowed;

		svg {
			path {
				stroke: #03434978;
			}
		}
	}
}
