@import '../../../../../../styles/index.scss';
.topbar {
  color: $white-color;

  .logo {
		display: block;
		width: 170px;
		height: 70px;
		object-fit: contain;
	  float: left;
	  margin-top: 12px;
  }

  .user-info__wrapper {
		width: auto;
	  	margin-top: 14px;
		background-color: $white-color;
		padding: 10px;
		display: flex;
		flex-direction: column;
    font-size: $base-font-size;
		border-radius: 10px;
		border: 1px solid #fff;
	  float: right;
		.user__info {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			.user__name {
				margin-left: 10px;
				font-weight: $font-weight-bold;
			}

			.menu__arrow {
				display: inline-block;
				width: 12px;
				height: 12px;
				margin-left: 15px;
				transform: rotate(-90deg);
			}

			.arrow__up {
				transform: rotate(90deg);
			}

			.user__avatar {
				height: 100%;
				width: 30px;
				object-fit: cover;
			}
		}


		.dropdown__menu {
			margin-top: 20px;
			display: flex;
			justify-content: center;
		}
  }

}
.navigation {
	padding-top: 35px;
	float: left;

	.nav__label {
		background-color: $nero-color;
		display: flex;
		color: $white-color;
		border-radius: 0px 10px 10px 0px;
		width: 100%;
		justify-content: center;
		padding: 10px;
		margin-bottom: 10px;
		min-height: 40px;
		height: auto;
		b {
			color: $white-color;
			margin-left: 5px;
			display: inline-block;
		}
	}

	nav {
		&.primary__navigation {
			margin: 0 auto;
			display: block;
			text-align: center;
			font-size: 16px;

			ul li {
				list-style: none;
				margin: 0 auto;
				border-left: 2px solid #3ca0e7;
				display: inline-block;
				padding: 0 30px;
				position: relative;
				text-decoration: none;
				text-align: center;
				font-family: arvo;
			}

			li a {
				color: black;
			}

			li a:hover {
				color: #3ca0e7;
			}

			li:hover {
				cursor: pointer;
			}

			ul li ul {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				padding-left: 0;
				left: 0;
				display: none;
				background: white;
			}

			ul li:hover > ul,
			ul li ul:hover {
				visibility: visible;
				opacity: 1;
				display: block;
				min-width: 250px;
				text-align: left;
				padding-top: 20px;
				box-shadow: 0px 3px 5px -1px #ccc;
			}

			ul li ul li {
				clear: both;
				width: 100%;
				text-align: left;
				margin-bottom: 20px;
				border-style: none;
			}

			ul li ul li a:hover {
				padding-left: 10px;
				border-left: 2px solid #3ca0e7;
				transition: all 0.3s ease;
			}
		}
	}

	a {

		text-decoration: none;

		&:hover {
			color: #3CA0E7;
		}

	}

	ul li ul li a { transition: all 0.5s ease; }
}
.navi__group{
	height: 80px;
	background-color: #FFF7FA;
	box-shadow: -2px 3px 5px 12px rgba(0,0,0,0.04);
	-webkit-box-shadow: -2px 3px 5px 12px rgba(0,0,0,0.04);
	-moz-box-shadow: -2px 3px 5px 12px rgba(0,0,0,0.04);
}
