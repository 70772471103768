.modal_main{
	width:  fit-content;
	height: 80vh;
	max-height: fit-content;
	overflow-y: scroll;
	overflow-x: hidden !important;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	min-width: 550px;
}


.main{
    flex:1;
		

    position: relative !important;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	padding: 10px;
	width: 100%;
}
.main .item{
    flex:1;

	position: relative;
    margin: 10px;
}
 .button{
	width: 90%;
}
.the_button{
	width: fit-content;
	margin-left: auto;
}
@media only screen and (max-width: 1200px) {
.modal_main{
		width: 600px;

}
	.main{
		display: inline-block;
		width: 540px;
	}
	.main .item{
			max-width: 525px;

}

}
