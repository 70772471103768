@import '../../../../styles/index.scss';

.primary__btn {
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    font-size: inherit;
    font-weight: $font-weight-bolder;
    color: inherit;
  }

  .left-icon{
    margin-right: 5px;
		height: 100%;
  }

  .right-icon{
    margin-left: 5px;
		height: 100%;
  }
}
