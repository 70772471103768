@mixin truncate-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin font($style, $weight, $size, $line-height) {
  font-family: $font-family;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}

@mixin full-screen {
  width: 100%;
	height: 100%;
  position: relative;
}

@mixin content-is-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin base-border($color) {
  border: 2px solid $color;
}

@mixin button-base($width, $height, $color, $backgroundColor, $border) {
  width: $width;
  height: $height;
  min-height: 50px;
  background: $backgroundColor;
  border: $border;
  border-radius: 10px;
  color: $color;
  font-weight: 600;
  font-size: 18px;
}
