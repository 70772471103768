@import '../../../../../styles/index.scss';

.categoriesAndTypologies__wrapper {
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .error {
    display: flex;
    color: $rosso-color;
    font-size: 12px;
    line-height: 1.2;
  }
}
