@import '../../../styles/index.scss';

.confirm-modal__title {
  font-weight: $font-weight-bolder;
}

.confirm-modal__actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  & > button:first-child {
    margin-right: 15px;
  }
}
