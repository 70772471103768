@import '../../../styles/index.scss';

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 49, 46, 0.9);
  backdrop-filter: blur(10px);
  z-index: 200;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background: $bianco-color;
  border-radius: 20px;
  min-width: 600px;
	align-items: center;
	max-height: 100vh;

  .modal__header {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}
