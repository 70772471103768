@import '../../../../../../../styles/index';

.gallery__wrapper {
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.confirm-modal__title {
  font-weight: $font-weight-bolder;
}

.confirm-modal__actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  & > button:first-child {
    margin-right: 15px;
  }
}
