@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;700&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

// FONT
$font-family: "Baloo Paaji 2", "Roboto", sans-serif;
$fontAwesome-family: "FontAwesome";
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 600;
$base-font-size: 18px;

$base-line-height: 1;
$base-letter-spacing: -0.005em;

