@import '../../../../../../../styles/index';

.edit-house__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

  label {
    font-size: 14px;
    font-weight: $font-weight-bolder;
  }
}

.form__disabled {
  div > textarea:disabled,
  div > input:disabled {
    background-color: transparent;
    padding: 0;
    color: $nero-color;
  }

  div > textarea:disabled {
    padding: 15px 0;
  }
}

.color__violet {
	color: $violet-color;
	font-weight: $font-weight-bolder;
}

.text__bold {
	font-weight: $font-weight-bolder;
}
