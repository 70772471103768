@import '../../../../../../../../../styles/index';

.image__main {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;

  .image__info {
    width: 70%;
    margin-left: 20px;
    position: relative;
    .actions__wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      &>*:first-child {
        margin-right: 10px;
      }
    }

    .edit-image__form {
      display: flex;
      flex-direction: column;

      div:nth-child(2) {
        margin: 0;
        textarea {
          margin: 0;
        }
      }
      input {
        font-weight: $font-weight-bolder;
      }
      textarea {
        font-size: 14px;
      }
    }

    .form__disabled {
      div>textarea:disabled,
      div>input:disabled {
        background-color: transparent;
        padding: 0;
        color: $nero-color;
      }

      div>textarea:disabled {
        padding: 15px 0;
      }
    }
  }

  .image__wrapper {
    width: 30%;
    position: relative;
    .house-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    &>button {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}

.empty-gallery {
	padding: 40px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > p {
		margin-bottom: 30px;
	}
	.add-image-btn__wrapper {
		& > button {
			img {
				transform: rotate(45deg);
			}
		}
	}
}

.change-cover-modal__title {
	font-weight: $font-weight-bolder;
}

.change-cover-modal__actions {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	&>button:first-child {
		margin-right: 10px;
	}
}
