@import '../../../../../../../styles/index';

.error {
  display: flex;
  color: $rosso-color;
  font-size: 12px;
  line-height: 1.2;
}

.modal__upload {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  .modal__dropdown {
    margin-bottom: 30px
  }
}
